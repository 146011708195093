<template>
  <div class="print-container">
    <el-table :data="tableData" border highlight-current-row>
      <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" width="150px"/>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'HuijiliWebTablePrint',

  data() {
    return {
      tableData: [],
      tableHeader: []
    };
  },

  mounted() {
    document.title = '2024年1月郑州地区签收表'
    const tableData = sessionStorage.getItem('tableData')
    if (tableData) {
      this.tableData = JSON.parse(tableData)
    }
    const tableHeader = sessionStorage.getItem('tableHeader')
    if (tableHeader) {
      this.tableHeader = JSON.parse(tableHeader)
    }
    setTimeout(() => {
      window.print()
    }, 1000)
  },
  destroyed() {
    sessionStorage.removeItem('tableData')
    sessionStorage.removeItem('tableHeader')
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
html,body {
  margin: 0;
  padding: 0;
}
.print-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
}
</style>